import React from 'react';
import Button from './button';

const PrevButton = ({
  buttonId='',
  bg = 'bg-white',
  className = '',
  colorClassName = ' ',
  onClick = null,
}) => (
  <Button
    id={buttonId}
    className={`
      h-14
      w-14
      sd:h-10
      sd:w-10
      group
      ${className}
      ${colorClassName}
    `}
    onClick={onClick}
  >
    <svg className={`w-full text-charcoal ${bg === 'bg-white' ? 'group-hover:text-white' : 'group-hover:text-tan'}`} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" id={buttonId+"_svg"}>
      <rect className="stroke-current text-charcoal group-hover:fill-current stroke-2" x="57" y="57" width="56" height="56" rx="28" transform="rotate(-180 57 57)" id={buttonId+'_rect'} />
      <path className="fill-current" d="M29.048 41.4371L16.712 28.9571L29.048 16.5731L30.584 18.1091L20.792 27.8531L41.336 27.8531L41.336 30.1091L20.792 30.1091L30.632 39.8531L29.048 41.4371Z" id={buttonId+'_path'}/>
    </svg>
  </Button>
);

export default PrevButton;
