import gsap from 'gsap/all';
import React, { useEffect, useRef } from 'react';
import { Typography } from '../../../../constants/typography';
import IconAsterisk from '../../../icons/asterisk';

const CreatorPanel3 = ({
  data,
  animationComplete,
  activeSlide,
}) => {
  const {
    asteriskFillText, asteriskOutlineText, description, eyebrow,
  } = data;
  const tl = useRef(null);
  const animEls = useRef({
    name: null,
    eyebrow: null,
    asteriskText: null,
  });

  useEffect(() => {
    if (!tl.current) {
      tl.current = gsap.timeline({ onComplete: animationComplete });
      tl.current.from(animEls.current.eyebrow, {
        y: 10,
        alpha: 0,
        duration: 0.5,
      }, 0.75);
      tl.current.from(animEls.current.title, {
        y: -10,
        alpha: 0,
        duration: 0.5,
      }, '-=0.5');
      tl.current.from(animEls.current.asteriskText, {
        y: 10,
        alpha: 0,
        duration: 0.5,
      });
      tl.current.pause();
    }
  });

  useEffect(() => {
    if (activeSlide) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [activeSlide]);
  return (
    <div
      className={`
        flex justify-center
        w-screen h-full
        pb-16
        transition-all duration-500
        opacity-0
        px-8
        lg:px-16
        ${activeSlide ? 'delay-500 opacity-100' : 'delay-0 opacity-0'}
      `}
    >
      <div className="h-full w-full max-w-[1180px] flex flex-col justify-center">
        <div className="w-full">
          <span className={`${Typography.eyebrow} uppercase lg:ml-[5px]`} ref={(el) => { animEls.current.eyebrow = el; }}>{eyebrow}</span>
          <h2 className={`${Typography.h3} sm:text-[36px]`} ref={(el) => { animEls.current.title = el; }}>
            {description}
          </h2>
        </div>
        <div className="w-full flex flex-col lg:flex-row lg:items-center mt-16" ref={(el) => { animEls.current.asteriskText = el; }}>
          <IconAsterisk className="w-[44px] mr-2 mb-2 lg:mb-0" />
          <span className=" text-[24px] lg:text-[50px] font-sans lg:font-medium leading-[110%]">
            <span className={`${Typography.strokedCharcoal} text-[36px] lg:text-[50px] font-medium block lg:inline-block mb-1 lg:mb-0`}>
              {asteriskOutlineText}
              &nbsp;
            </span>
            {asteriskFillText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreatorPanel3;
