import React from 'react';
import Button from './button';

const NextButton = ({
  buttonId='',
  bg = 'bg-white',
  className = '',
  colorClassName = '',
  onClick = null,
}) => (
  <Button
    id={buttonId}
    className={`
      h-14
      w-14
      sd:w-10
      sd:h-10
      group
      ${className}
      ${colorClassName}
    `}
    onClick={onClick}
  >
    <svg className={`w-full text-charcoal ${bg === 'bg-white' ? 'group-hover:text-white' : 'group-hover:text-tan'}`} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" id={buttonId+'_svg'}>
      <rect className="stroke-current text-charcoal group-hover:fill-current stroke-2" x="1" y="1" width="56" height="56" rx="28" id={buttonId+'_rect'} />
      <path className="fill-current" d="M28.952 16.5629L41.288 29.0429L28.952 41.4269L27.416 39.8909L37.208 30.1469L16.664 30.1469L16.664 27.8909L37.208 27.8909L27.368 18.1469L28.952 16.5629Z" id={buttonId+'_path'} />
    </svg>

  </Button>
);

export default NextButton;
