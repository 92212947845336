import React from 'react';
import { useSelector } from 'react-redux';
import { Constants } from '../constants';
import { Typography } from '../constants/typography';
import { selectIsLandscape, selectIsTablet } from '../state/reducers/utils';

const IconStack = ({ stack, stackCount, animationClass = '' }) => {
  let contentWidth;
  const isTablet = useSelector(selectIsTablet);
  const isLandscape = useSelector(selectIsLandscape);
  switch (stackCount) {
    case 3:
      contentWidth = 'w-[280px] sm:w-[70%] tl:max-w-[33.33%]';
      break;
    case 4:
      contentWidth = 'w-[240px] tl:max-w-[25%]';
      break;
    default:
      contentWidth = 'w-full';
      break;
  }
  return (
    <div
      className={`
        flex flex-col items-center
        ${isTablet && isLandscape && 'flex-1'}
        ${contentWidth} max-w-[80%] lg:max-w-[280px]
        tl:max-w-[20%]
        ${animationClass}
        ${Constants.fadeUp}
      `}
      data-scroll
      data-scroll-class="fade-up-visible"
      data-scroll-offset="25%"
      data-scroll-repeat="true"
    >
      <div className="relative max-w-[161px]">
        <img className="w-40" src={stack.icon.localFile.publicURL} alt={stack.title} />
      </div>
      <h5 className={`${Typography.h5} my-3 text-center`}>
        {stack.title}
      </h5>
      {
        stack.subtitle
        && (
          <p className={`${Typography.body2} text-center max-w-[95%]`}>
            {stack.subtitle}
          </p>
        )
      }
    </div>
  );
};

export default IconStack;
