import React from 'react';
import { Constants } from '../constants';
import { Typography } from '../constants/typography';
import PrimaryButton from './buttons/primary';
import Carousel from './carousel';
import IconStack from './iconStack';

const ValueProps = ({
  id, content, carouselConfig = {}, primaryButtonProps,
}) => {
  const {
    title, subtitle, items, buttonA,
  } = content;
  const delays = ['delay-0', 'delay-1', 'delay-2', 'delay-3', 'delay-4'];
  const propsWidth = items.length === 3 ? 'w-[1040px]' : 'w-[1300px]';
  return (
    <div
      id={id}
      className={`
        ${Constants.panelBase}
        sm:!h-auto
        tp:!h-auto
        bg-white
        flex flex-col items-center justify-center
        py-18
        dark-header header-section
      `}
    >
      <h3
        className={`
          ${Typography.h3}
          text-charcoal
          text-center
          px-8
          max-w-[80vw]
          ${id === 'smarter-ad-space' ? 'tp:max-w-[75vw]' : 'tp:max-w-[60vw]'}
          tl:max-w-[60vw]
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="25%"
        data-scroll-repeat="true"
      >
        {title}
      </h3>
      {
        subtitle
        && (
          <p
            className={`
              ${Typography.body1}
              text-charcoal text-center
              px-8
              max-w-[605px]
              mt-4
              ${Constants.fadeUp}
            `}
            data-scroll
            data-scroll-class="fade-up-visible"
            data-scroll-offset="15%"
            data-scroll-repeat="true"
          >
            {subtitle}
          </p>
        )
      }
      <Carousel className="tp:block lg:hidden" inactiveColor={carouselConfig.inactiveColor}>
        {items.map((stack) => (
          <div key={`carousel-mobile-value-props-${stack.title}`} className="w-full flex justify-center">
            <IconStack stack={stack} />
          </div>
        ))}
      </Carousel>
      <div
        className={`
          hidden
          tp:hidden
          lg:flex
          lg:flex-row
          ${propsWidth}
          max-w-[95vw]
          justify-between
          space-y-12 lg:space-y-0
          py-18 px-8
        `}
      >
        {
          items.map((stack, index) => (
            <IconStack
              key={`carousel-desktop-value-props-${stack.title}`}
              stack={stack}
              stackCount={items.length}
              width={items.length === 4 ? '235px' : '280px'}
              animationClass={delays[index]}
            />
          ))
        }
      </div>
      <PrimaryButton
        className={`
          ${Constants.fadeUp}
        `}
        color="charcoal"
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="5%"
        data-scroll-repeat="true"
        {...primaryButtonProps}
      >
        {buttonA.label}
      </PrimaryButton>
    </div>
  );
};

export default ValueProps;
