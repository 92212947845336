/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
import gsap, { SplitText, ScrollTrigger } from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '../../constants';
import { Typography } from '../../constants/typography';
import {
  selectIsLandscape,
  selectIsMobile,
  selectIsTablet,
  selectScrollReady,
  setHeaderColorOverride,
  setHideHeader,
} from '../../state/reducers/utils';
import ScrollButton from '../buttons/scroll';
import SkewRotateAsterisk from '../icons/skew-rotate-asterisk';

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

const CreatorsHero = ({ content }) => {
  const dispatch = useDispatch();
  const isTablet = useSelector(selectIsTablet);
  const isLandscape = useSelector(selectIsLandscape);
  const scrollY = useRef(0);
  const [stickyProps, setStickyProps] = useState({});
  const currentIndex = useRef(0);
  const hero = useRef(null);
  const asterisk = useRef(null);
  const isMobile = useSelector(selectIsMobile);
  const [current, setCurrent] = useState(0);
  const [maxHeight, setMaxHeight] = useState(null);
  const [scrollSet, setScrollSet] = useState(false);
  const panel1 = useRef(null);
  const panel2 = useRef(null);
  const tl = useRef(null);
  const scrollReady = useSelector(selectScrollReady);
  const animationComplete = () => {
    if (isMobile || isTablet) return;
    setMaxHeight(scrollY.current + window.innerHeight);
    dispatch(setHideHeader(true));
  };

  useEffect(() => {
    if (isMobile === null || isTablet === null) return;
    if (!isMobile && !isTablet) {
      setStickyProps({
        'data-scroll': true,
        'data-scroll-sticky': !isTablet && !isMobile,
        'data-scroll-target': '#brands-hero',
      });
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (window.locoScroll) {
      window.locoScroll.update();
      window.locoScroll.start();
    }
    if (!maxHeight) {
      dispatch(setHideHeader(false));
    }
  }, [maxHeight]);

  useEffect(() => {
    if (isMobile === null || tl.current) return;
    dispatch(setHeaderColorOverride('text-white'));
    tl.current = gsap.timeline({ onComplete: animationComplete });
    const firstSplit = new SplitText(panel1.current.querySelector('h1'), { type: 'words' });
    const secondSplit = new SplitText(panel2.current.querySelector('h2'), { type: 'words' });
    const baseDelay = 0.4;
    tl.current.from(firstSplit.words, {
      duration: 0.8, y: 50, alpha: 0, stagger: 0.05, ease: 'expo.inOut',
    }, baseDelay);
    if (isMobile || isTablet) {
      tl.current.from(panel1.current.querySelector('.scroll-button'), {
        duration: 0.8, y: 100, alpha: 0, ease: 'expo.inOut',
      }, '-=0.5');
    }
    tl.current.addLabel('endIn');
    if (!isMobile && !isTablet) {
      tl.current.to(firstSplit.words, {
        duration: 0.8, y: -50, alpha: 0, stagger: 0.05, ease: 'expo.inOut',
      }, '-=0');
    }
    tl.current.from(secondSplit.words, {
      duration: 0.8, y: 50, stagger: 0.05, ease: 'expo.inOut',
    }, isMobile || isTablet ? '-=0' : '-=0.7');
    tl.current.from(secondSplit.words, {
      duration: 0.8, alpha: 0, stagger: 0.05, ease: 'expo.inOut',
    }, '-=0.8');
    tl.current.from(panel2.current.querySelector('p'), {
      duration: 0.6, y: 50, alpha: 0, ease: 'expo.out',
    }, '-=0.6');
    if (isMobile || isTablet) {
      tl.current.from(panel2.current.querySelector('.scroll-button'), {
        duration: 0.8, y: 100, alpha: 0, ease: 'expo.inOut',
      }, '-=0.5');
    }
    tl.current.addLabel('endOut');
    tl.current.pause();
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (isMobile === null || isTablet === null) return;
    if (current === 0) {
      setMaxHeight(null);
      tl.current.tweenTo('endIn');
    } else if (current === 1) {
      tl.current.tweenTo('endOut');
    }
  }, [current, isMobile, isTablet]);

  useEffect(() => {
    if (scrollReady && window.locoScroll && !scrollSet && isMobile !== null) {
      setScrollSet(true);
      window.locoScroll.on('scroll', (e) => {
        scrollY.current = e.scroll.y;
        if (e.scroll.y > window.innerHeight * (isMobile ? 0.333 : isTablet ? 0.66 : 0.25)) {
          if (currentIndex.current !== 1) {
            currentIndex.current = 1;
            setCurrent(1);
            if (!isTablet && !isMobile) {
              window.locoScroll.stop();
            }
          }
        } else {
          if (currentIndex.current !== 0) {
            setCurrent(0);
            currentIndex.current = 0;
            tl.current.tweenTo('endIn');
          }
        }
      });
    }
  }, [scrollReady]);

  return (
    <div className="bg-charcoal light-header header-section relative overflow-hidden tl:h-auto lg:h-[500vh] max-w-screen" style={{ maxHeight }} id="brands-hero" ref={hero}>
      <div
        className={`
          ${!isTablet && 'lg:!absolute'}
          top-0
          left-0
          ${Constants.panelBase}
          sm:responsive-h-screen
          tp:responsive-h-screen
          tl:responsive-h-screen
          light-header header-section
          flex items-end justify-start
          px-6 pb-32 tp:pb-32 sd:pb-16 lg:p-16
          z-10
          text-yellow
        `}
        ref={panel1}
        {...stickyProps}
      >
        <h1
          className={`
            z-30
            w-full
            ${Typography.h1}
            ${Typography.strokedYellow}
            text-left max-w-[830px]
          `}
        >
          {content.outlineText}
        </h1>
        {
          (isMobile || isTablet) && (
            <div
              className={`scroll-button ${isTablet && isLandscape ? Constants.stickyScrollButton : Constants.stickyScrollButtonMobile}`}
            >
              <ScrollButton
                location='creators'
                arrowNo={current+1}
                dynamic
                positionClassName="relative"
                colorClassName="text-yellow"
                scrollTo="no-catch"
              />
            </div>
          )
        }
      </div>
      <div
        className={`
          ${!isTablet && 'lg:!absolute'}
          top-0
          left-0
          ${Constants.panelBase}
          sm:responsive-h-screen
          tl:responsive-h-screen
          light-header header-section
          flex items-end justify-start
          px-6 pb-32 tp:pb-32 sd:pb-16 lg:p-16
          z-10
        `}
        {...stickyProps}
      >
        <div
          className="w-full h-full right-16 left-16 flex flex-col justify-end text-yellow"
          ref={panel2}
        >
          <h2 className={`${Typography.h1} text-left max-w-[1200px]`}>
            {content.fillText}
          </h2>
          <p className={`mt-8 lg:mt-16 ${Typography.body1} max-w-[693px]`}>
            {content.bodyText}
          </p>
        </div>
        {
          (isMobile || isTablet) && (
            <div
              className={`scroll-button ${isTablet && isLandscape ? Constants.stickyScrollButton : Constants.stickyScrollButtonMobile}`}
            >
              <ScrollButton
                location='creators'
                arrowNo={current+1}
                dynamic
                positionClassName="relative"
                colorClassName="text-yellow"
                scrollTo="no-catch"
              />
            </div>
          )
        }
      </div>
      <div className="fixed pointer-events-none tl:fixed lg:absolute top-0 left-0 z-0 w-full h-screen sm:responsive-h-screen tl:responsive-h-screen" data-scroll data-scroll-sticky data-scroll-target="#brands-hero">
        <div className="relative" ref={asterisk}>
          <SkewRotateAsterisk pathClassName="text-yellow" />
        </div>
      </div>
      {
        !isMobile && !isTablet && (
          <div
            className={Constants.stickyScrollButton}
            {...stickyProps}
          >
            <ScrollButton
              location='creators'
              arrowNo={current+1}
              dynamic
              positionClassName="relative"
              colorClassName="text-yellow"
              scrollTo="no-catch"
            />
          </div>
        )
      }
    </div>
  );
};

export default CreatorsHero;
