/* eslint-disable react/no-danger */
import React from 'react';
import { Constants } from '../../constants';
import { sendTagEvent } from '../../constants/google-tags';
import { Typography } from '../../constants/typography';
import PrimaryButton from '../buttons/primary';
import Carousel from '../carousel';
import IconAsterisk from '../icons/asterisk';
import { utmGet } from '../../utils/utmExtractor';

const utm = utmGet();
const connectUrl = process.env.NODE_ENV === Constants.PRODUCTION ? `https://connect.spotter.la/${utm}` : `https://staging-cn.spotter.la/${utm}`;

const NextFact = ({ data }) => (
  <div className="flex flex-col w-full lg:max-w-[349px] px-8 tl:px-0 lg:px-0">
    <h2 className={`${Typography.h3} !leading-[90%]`}>
      <span className={`block ${Typography.strokedCharcoal}`}>{data.titleOutline}</span>
      {data.titleFill}
    </h2>
    <div
      className={`${Typography.body2} w-full mt-5 lg:mt-8 tp:max-w-[450px]`}
      dangerouslySetInnerHTML={{ __html: data.description }}
    />
  </div>
);

const CreatorsAreYouNext = ({ content }) => {
  const {
    title, ctaDescription, ctaLabel, facts,
  } = content;
  return (
    <div
      className={`
        w-full
        bg-yellow
        h-auto py-16 lg:py-32
        flex flex-col justify-center lg:justify-start
        px-8
        lg:px-16
      `}
    >
      <h1
        className={`
          ${Typography.h1}
          sm:!text-[72px] tp:!text-[72px]
          ${Typography.strokedCharcoal}
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="20%"
        data-scroll-repeat="true"
      >
        {title}
      </h1>
      <div className="flex mt-24  flex-col w-full">
        <div
          className={`
            hidden
            tl:flex lg:flex justify-between items-start
            w-full
            flex-1
            mb-28
            lg:space-x-5
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="20%"
          data-scroll-repeat="true"
        >
          {facts.map((fact) => (
            <NextFact key={`${fact.titleFill}-${fact.titleOutline}-desktop`} data={fact} />
          ))}
        </div>
        <div
          className={`
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="20%"
          data-scroll-repeat="true"
        >
          <Carousel className="w-full tl:hidden lg:hidden mb-28 -ml-8" style={{ width: 'calc(100% + 4rem)' }} navPosition="justify-start px-6" inactiveColor="after:bg-gray1 after:opacity-50" activeColor="after:bg-charcoal">
            {facts.map((fact) => (
              <NextFact key={`${fact.titleFill}-${fact.titleOutline}-mobile`} data={fact} />
            ))}
          </Carousel>
        </div>
        <div
          className={`
            lg:max-w-[349px]
            w-full
            self-end
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="20%"
          data-scroll-repeat="true"
        >
          <IconAsterisk className="w-14 h-14" />
          <div
            className={`${Typography.body1} mt-5 mb-8 w-full lg:w-10/12`}
            dangerouslySetInnerHTML={{ __html: ctaDescription }}
          />
          <PrimaryButton
            onClick={() => {
              sendTagEvent('click', {
                event_category: 'Creators',
                event_label: 'Introduce yourself',
              });
            }}
            href={connectUrl}
            target="_blank"
            hoverClassName="hover:border-white hover:bg-white hover:text-charcoal"
          >
            {ctaLabel}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreatorsAreYouNext;
