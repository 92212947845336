/* eslint-disable max-len */
import gsap from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Typography } from '../../../../constants/typography';
import Grid from '../../../grid';

const CreatorPanel2 = ({
  creatorId = '',
  panelKey,
  data,
  animationComplete,
  activeSlide,
}) => {
  const {
    eyebrow, heading, social, audio, image,
  } = data;
  const [playing, setPlaying] = useState(false);
  const tl = useRef(null);
  const [subtitle, setSubtitle] = useState(null);
  const animEls = useRef({
    eyebrow: null,
    title: null,
    image: null,
    social: null,
    quotes: null,
    audioPlayer: null,
  });
  const audioPlayer = useRef(null);
  const togglePlaying = () => {
    setPlaying(!playing);
  };
  const [audioPanelKey, setAudioPanelKey] = useState(0);

  useEffect(() => {
    if (playing) {
      audioPlayer.current.play();
    } else {
      audioPlayer.current.pause();
    }
  }, [playing]);

  useEffect(() => {
    if (panelKey === 1) setAudioPanelKey(1);
    else if (panelKey === 3) setAudioPanelKey(2);
  }, [panelKey]);

  useEffect(() => {
    if (!tl.current) {
      tl.current = gsap.timeline({ onComplete: animationComplete });
      tl.current.from(animEls.current.eyebrow, {
        y: 10,
        alpha: 0,
        duration: 0.5,
      }, 0.75);
      tl.current.from(animEls.current.title, {
        y: -10,
        alpha: 0,
        duration: 0.5,
      }, '-=0.5');
      tl.current.from(animEls.current.image, {
        y: -50,
        alpha: 0,
        duration: 0.5,
      }, '-=0.25');
      tl.current.from(animEls.current.social, {
        y: 50,
        alpha: 0,
        duration: 0.5,
      }, '-=0.5');
      tl.current.from(animEls.current.quotes, {
        y: -5,
        alpha: 0,
        duration: 0.5,
      }, '-=0.25');
      tl.current.from(animEls.current.audioPlayer, {
        y: 5,
        alpha: 0,
        duration: 0.5,
      }, '-=0.25');
      tl.current.pause();
    }
  });

  useEffect(() => {
    if (activeSlide) {
      tl.current.play();
    } else {
      tl.current.reverse();
      setPlaying(false);
    }
  }, [activeSlide]);

  const audioLoaded = () => {
    if (subtitle !== null) return;
    setSubtitle(audioPlayer.current.textTracks[0].cues[0].text);
    audioPlayer.current.textTracks[0].oncuechange = () => {
      const currentCue = audioPlayer.current.textTracks[0].activeCues
        && audioPlayer.current.textTracks[0].activeCues.length
        ? audioPlayer.current.textTracks[0].activeCues[0].text
        : null;
      if (currentCue) {
        setSubtitle(currentCue);
      }
    };
  };

  useEffect(() => {
    if (audioPlayer.current) {
      if (audioPlayer.current.readyState === 4) {
        audioLoaded();
      }
      audioPlayer.current.oncanplay = audioLoaded;
    }
  }, []);

  return (
    <div
      className={`
        flex justify-center
        w-screen h-full
        transition-all duration-500
        opacity-0
        sd:px-16
        ${activeSlide ? 'opacity-100' : 'opacity-0'}
      `}
    >
      <div className="h-full w-full max-w-[1180px] flex flex-col justify-center">
        <Grid horizontalPadding="px-8 lg:p-0" gapConfig="" className="h-full lg:h-auto" wrapperClassName="h-[80%] lg:h-auto">
          <div className="col-span-full lg:col-span-6">
            <span className={`${Typography.eyebrow} uppercase lg:ml-[5px] block`} ref={(el) => { animEls.current.eyebrow = el; }}>{eyebrow}</span>
            <h2 className={`${Typography.h3} sm:text-[36px]`} ref={(el) => { animEls.current.title = el; }}>
              {heading}
            </h2>
          </div>
          <div className="relative z-0 col-start-2 col-end-5 tl:col-start-5 tl:col-end-12 tp:col-start-3 tp:col-end-12 lg:col-start-7 lg:col-end-12">
            <div
              ref={(el) => { animEls.current.image = el; }}
              className="w-full h-full"
            >
              <div className="w-full h-full flex justify-end items-end">
                <GatsbyImage
                  image={getImage(image.localFile)}
                  alt={heading}
                  className="w-10/12 lg:w-full"
                />
              </div>
              <div
                className={`
                  sm:hidden
                  ${Typography.h5}
                  leading-[100%]
                  inline-block
                  transform rotate-90
                  absolute
                  bottom-0
                  -right-4
                  origin-bottom-right
                `}
                ref={(el) => { animEls.current.social = el; }}
              >
                {social}
              </div>
            </div>
          </div>
          <div className="relative col-span-full tp:col-span-5 tp:col-start-2 lg:col-span-5 lg:col-start-3 flex z-10 lg:pr-8 mt-4 tl:mt-[-120px] lg:mt-[-260px]">
            <span className={`relative -top-24 -left-16 lg:relative lg:-top-24 lg:left-auto block font-medium text-[400px] ${Typography.strokedWhite} leading-[275px] h-[137px]`} ref={(el) => { animEls.current.quotes = el; }}>
              “
            </span>
            <div
              className={`
                flex flex-col flex-1
                lg:-ml-10
                py-6 lg:pb-5 px-6
                rounded-[22px] border-2 border-charcoal
                bg-white
                overflow-hidden
                absolute
                lg:right-0
                w-full lg:w-9/12 max-w-[400px]
              `}
              ref={(el) => { animEls.current.audioPlayer = el; }}
            >
              <span className={`text-center flex items-center ${Typography.body1} px-2 hidden lg:block`}>
                {subtitle}
              </span>
              <div className="flex lg:mt-7 items-center">
                <button
                  id={`spotterla_creators_${creatorId.toLowerCase()}clip${audioPanelKey}`}
                  onClick={togglePlaying}
                  type="button"
                  aria-hidden="true"
                  className={`
                    group
                    flex-0 flex items-center justify-center
                    h-11 w-11
                    rounded-full border-2 border-charcoal hover:bg-charcoal
                  `}
                >
                  <span
                    className={`
                      ml-1
                      play-button
                      group-hover:play-hover
                      ${playing ? 'paused ml-0 ' : 'ml-1'}
                    `}
                  />
                </button>
                <div
                  className={`
                    ${playing && 'playing'}
                    flex flex-1
                    h-8
                    pl-4
                  `}
                >
                  <div className="container">
                    <div className="wave wave1 wave-active-1" />
                    <div className="wave wave2 wave-active-2" />
                    <div className="wave wave3 wave-active-3" />
                    <div className="wave wave4 wave-active-4" />
                    <div className="wave wave5 wave-active-5" />
                    <div className="wave wave6 wave-active-6" />
                    <div className="wave wave7 wave-active-7" />
                    <div className="wave wave8 wave-active-8" />
                    <div className="wave wave9 wave-active-9" />
                    <div className="wave wave10 wave-active-10" />
                    <div className="wave wave11 wave-active-11" />
                    <div className="wave wave12 wave-active-12" />
                    <div className="wave wave13 wave-active-13" />
                    <div className="wave wave14 wave-active-14" />
                    <div className="wave wave15 wave-active-15" />
                    <div className="wave wave16 wave-active-16" />
                    <div className="wave wave17 wave-active-17" />
                    <div className="wave wave18 wave-active-18" />
                    <div className="wave wave19 wave-active-19" />
                    <div className="wave wave20 wave-active-20" />
                    <div className="wave wave21 wave-active-21" />
                    <div className="wave wave22 wave-active-22" />
                    <div className="wave wave23 wave-active-23" />
                    <div className="wave wave24 wave-active-24" />
                    <div className="wave wave25 wave-active-25" />
                    <div className="wave wave26 wave-active-26" />
                    <div className="wave wave27 wave-active-27" />
                    <div className="wave wave28 wave-active-28" />
                    <div className="wave wave29 wave-active-29" />
                    <div className="wave wave30 wave-active-30" />
                    <div className="wave wave31 wave-active-31" />
                    <div className="wave wave32 wave-active-32" />
                    <div className="wave wave33 wave-active-33" />
                    <div className="wave wave34 wave-active-34" />
                    <div className="wave wave35 wave-active-35" />
                    <div className="wave wave36 wave-active-36" />
                    <div className="wave wave37 wave-active-37" />
                    <div className="wave wave38 wave-active-38" />
                    <div className="wave wave39 wave-active-39" />
                  </div>
                </div>
              </div>
              <audio ref={audioPlayer} onEnded={() => setPlaying(false)}>
                <source src={audio.audioFile.localFile.publicURL} type="audio/mp3" />
                <track default kind="captions" srcLang="en" src={audio.captionsFile.localFile.publicURL} />
              </audio>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default CreatorPanel2;
