/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import 'swiper/css';
import CreatorPanel1 from './panels/panel1';
import CreatorPanel2 from './panels/panel2';
import CreatorPanel3 from './panels/panel3';
import CreatorPanel4 from './panels/panel4';
import NextButton from '../../buttons/next';
import PrevButton from '../../buttons/prev';
import ScrollButton from '../../buttons/scroll';
import { Typography } from '../../../constants/typography';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import { selectIsMobile, selectIsTablet } from '../../../state/reducers/utils';
import { sendTagEvent } from '../../../constants/google-tags';

const CreatorCaseStudy = ({
  id,
  caseStudy,
  isLast,
  scrollToCurrent,
  goToNext,
  animationComplete,
  caseStudyIndex,
}) => {
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const [allowTouchMove, setAllowTouchMove] = useState(null);
  const caseStudyRef = useRef(null);
  const [showNext, setShowNext] = useState(false);
  const [active, setActive] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextActive, setNextActive] = useState(true);
  const [prevActive, setPrevActive] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const showNav = true;
  const si = useRef(null);
  const nextSlide = () => {
    swiper.slideNext();
    scrollToCurrent(caseStudyIndex);
  };
  const prevSlide = () => {
    swiper.slidePrev();
    scrollToCurrent(caseStudyIndex);
  };

  useEffect(() => {
    if (isMobile === null) return;
    setAllowTouchMove(isMobile || isTablet);
  });
  useIntersectionObserver({
    target: caseStudyRef,
    rootMargin: '0px',
    threshold: [0, 0.5, 1],
    onIntersect: ([{ isIntersecting, intersectionRatio }]) => {
      if (isIntersecting && intersectionRatio >= 0.5) {
        setActive(true);
      } else if (intersectionRatio <= 0) setActive(false);
    },
  });
  const transition = (swiperInstance) => {
    setCurrentIndex(swiperInstance.activeIndex);
    setShowNext(swiperInstance.activeIndex === caseStudy.slides.length - 1);
    scrollToCurrent(caseStudyIndex);
    if (swiperInstance.activeIndex >= caseStudy.slides.length - 1) {
      setNextActive(false);
    } else {
      setNextActive(true);
    }
    if (swiperInstance.activeIndex === 0) {
      setPrevActive(false);
    } else {
      setPrevActive(true);
    }
  };

  useEffect(() => {
    si.current = swiper;
  }, [swiper]);

  const completeAnimation = (key) => {
    const allIds = key.split('_');
    sendTagEvent('section', {
      event_category: 'Creators',
      event_label: `Carousel / ${allIds.join(' ')}`,
    });
    if (si.current) {
      si.current.enable();
    }
    animationComplete();
  };
  const sliderClassName = 'w-full h-full flex justify-center';

  return (
    <div
      className="case-study-wrapper"
      data-csi={caseStudyIndex}
      ref={caseStudyRef}
    >
      <div
        className={`
          ${caseStudy.backgroundColor}
          case-study
          relative
          h-screen
          lg:max-h-[816px]
          overflow-hidden
          flex flex-col justify-center items-center
          ${active ? 'transition-all duration-500 ease-in-out opacity-100 pointer-events-auto' : 'transition-all duration-500 ease-in-out opacity-0 pointer-events-none'}
        `}
      >
        {
          allowTouchMove !== null && (
            <div
              className={`
                relative
                w-full max-w-[1550px]
                h-full max-h-full
              `}
            >
              <Swiper
                key={id}
                modules={[Keyboard]}
                allowTouchMove={allowTouchMove}
                touchReleaseOnEdges
                keyboard
                slidesPerView={1}
                speed={1000}
                onTransitionStart={transition}
                onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
                className={`
                  tp:true-responsive-h-screen
                  h-full
                  !overflow-visible
                  flex items-center justify-center
                `}
              >
                {

                  caseStudy.slides.map((panel, idx) => {
                    const key = `${id}-${panel.type}-${caseStudyIndex}-${idx}`;
                    switch (panel.type) {
                      case 'Page_Creators_TrueStories_caseStudies_Slides_IntroTemplate':
                        return (
                          <SwiperSlide
                            key={key}
                            className={`
                              w-full h-full max-h-[100%]
                              flex justify-center items-center
                            `}
                          >
                            {({ isActive }) => (
                              <CreatorPanel1
                                active={active}
                                activeSlide={isActive}
                                animationComplete={() => completeAnimation(key)}
                                data={{ heading: caseStudy.title, ...panel }}
                              />
                            )}
                          </SwiperSlide>
                        );
                      case 'Page_Creators_TrueStories_caseStudies_Slides_AudioTemplate':
                        return (
                          <SwiperSlide key={key} className={sliderClassName}>
                            {({ isActive }) => (
                              <CreatorPanel2
                                creatorId={id}
                                panelKey={idx}
                                active={active}
                                activeSlide={isActive}
                                animationComplete={() => completeAnimation(key)}
                                data={panel}
                              />
                            )}
                          </SwiperSlide>
                        );
                      case 'Page_Creators_TrueStories_caseStudies_Slides_StatisticsTemplate':
                        return (
                          <SwiperSlide key={key} className={sliderClassName}>
                            {({ isActive }) => (
                              <CreatorPanel3
                                active={active}
                                activeSlide={isActive}
                                animationComplete={() => completeAnimation(key)}
                                data={panel}
                              />
                            )}
                          </SwiperSlide>
                        );
                      case 'Page_Creators_TrueStories_caseStudies_Slides_VideoTemplate':
                        return (
                          <SwiperSlide key={key} className={sliderClassName}>
                            {({ isActive }) => (
                              <CreatorPanel4
                                creatorId={id}
                                active={active}
                                activeSlide={isActive}
                                animationComplete={() => completeAnimation(key)}
                                data={panel}
                                goToNext={() => goToNext(caseStudyIndex)}
                                isLast={isLast}
                              />
                            )}
                          </SwiperSlide>
                        );
                      default:
                        return (
                          <SwiperSlide key={key} className={sliderClassName}>
                            <div />
                          </SwiperSlide>
                        );
                    }
                  })
                }
              </Swiper>
              {
                !isLast && (
                  <div
                    className={`
                      ${Typography.body1}
                      flex items-center
                      absolute
                      left-8
                      lg:left-auto
                      tp:left-auto
                      lg:right-16
                      tp:right-16
                      bottom-7
                      tp:bottom-16
                      lg:bottom-14
                      transition-all duration-300
                      z-30
                      cursor-pointer
                      ${showNext ? 'translate-y-0 opacity-100 pointer-events-auto delay-500' : 'translate-y-[5px] opacity-0 pointer-events-none delay-0'}
                    `}
                    onClick={() => goToNext(caseStudyIndex)}
                    aria-hidden="true"
                  >
                    next story
                    <ScrollButton className="ml-1" positionClassName="relative" />
                  </div>
                )
              }
              {
                caseStudy.slides.length > 1
                && (
                  <>
                    <PrevButton
                      buttonId={`spotterla_creators_${id.toLowerCase()}${currentIndex}_left_arrow`}
                      className={`
                        hidden lg:flex
                        absolute
                        sd:left-4
                        left-8 lg:left-7
                        top-[50%] -translate-y-1/2
                        transition-all duration-200
                        z-30
                        ${active && prevActive ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
                      `}
                      bg={caseStudy.backgroundColor}
                      onClick={prevSlide}
                    />
                    <NextButton
                      buttonId={`spotterla_creators_${id.toLowerCase()}${currentIndex}_right_arrow`}
                      className={`
                        hidden lg:flex
                        absolute
                        sd:right-4
                        right-8 lg:right-7
                        lg:top-[50%] lg:-translate-y-1/2
                        bottom-7 lg:bottom-auto
                        transition-all duration-200
                        z-30
                        translate-x-full
                        opacity-0
                        ${active && (showNav || currentIndex > 0) ? 'translate-x-0' : 'translate-x-full'}
                        ${active && showNav && nextActive ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
                      `}
                      bg={caseStudy.backgroundColor}
                      onClick={nextSlide}
                    />
                    <div
                      className={`
                        flex lg:justify-end
                        absolute
                        bottom-6 lg:bottom-12 tp:bottom-10
                        w-full
                        sm:right-0
                        sm:w-full
                        tp:w-full
                        lg:right-24
                        tp:right-0
                        space-x-2
                        lg:pr-4
                        z-20
                        lg:max-w-[280px]
                        transition-all duration-500
                        ${active && showNav && (currentIndex !== caseStudy.slides.length - 1) ? 'opacity-100 lg:bottom-16 pointer-events-auto' : 'opacity-0 pointer-events-none lg:bottom-12'}
                      `}
                    >
                      <div className="w-full lg:max-w-[280px] flex justify-center lg:justify-start space-x-2">
                        {caseStudy.slides.map((panel, index) => (
                          <button
                            id={`spotterla_creators_${id.toLowerCase()}_dash_${index}`}
                            key={`${id}-${panel.type}-${index}`}
                            type="button"
                            aria-hidden="true"
                            onClick={
                              () => swiper.slideTo(index, Math.abs(currentIndex - index) * 250)
                            }
                            className={`
                              flex items-center
                              w-14 lg:w-16
                              h-4
                              after:w-full
                              after:block
                              after:h-[2px]
                              ${currentIndex === index ? 'after:bg-charcoal' : 'after:bg-gray1 after:opacity-50'}
                            `}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CreatorCaseStudy;
