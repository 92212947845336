/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import gsap from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useSelector } from 'react-redux';
import { Constants } from '../../../../constants';
import { Colors } from '../../../../constants/colors';
import { Typography } from '../../../../constants/typography';
import { selectIsMobile, selectIsTablet } from '../../../../state/reducers/utils';
import Grid from '../../../grid';
import IconAsterisk from '../../../icons/asterisk';
import IconAsteriskOutline from '../../../icons/asterisk-outline';

const CreatorPanel1 = ({
  data,
  active,
  animationComplete,
  activeSlide,
}) => {
  const {
    description, eyebrow, imagePosition, heading, subheading, image,
  } = data;
  const asteriskStyle = data.asteriskStyle === 'lg' ? 1 : data.asteriskStyle === 'xxl' ? 2 : 3;
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const tl = useRef(null);
  const [nameCount, setNameCount] = useState(0);
  const animEls = useRef({
    name: null,
    eyebrow: null,
    panel: null,
    nameList: null,
    asterisk: null,
    title: null,
    summary: null,
    image: null,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (heading.indexOf('<br') === -1) {
        setNameCount(Math.ceil((window.innerHeight / 86) * 1.5));
      } else {
        setNameCount(Math.ceil((window.innerHeight / 150) * 1.5));
      }
    }
  });

  useEffect(() => {
    if (!tl.current && isMobile !== null && nameCount > 0) {
      tl.current = gsap.timeline({ onComplete: animationComplete });
      tl.current.from(animEls.current.name, {
        duration: 1,
        alpha: 0,
      }, 0);
      tl.current.from(animEls.current.name, {
        y: (
          animEls.current.panel.offsetParent.offsetTop + animEls.current.nameList.offsetHeight
        ) * -1,
        duration: 2,
        ease: 'power3.out',
      }, '-=1');
      tl.current.to(animEls.current.name.querySelectorAll('.fade-out'), {
        duration: 0.25,
        alpha: 0,
        stagger: -1 * (1.75 / nameCount),
      }, '-=2');
      tl.current.to('.fill-me', {
        textFillColor: Colors.charcoal,
        textStrokeColor: 'transparent',
        duration: 0.125,
      }, '-=0.75');
      tl.current.from(animEls.current.eyebrow, {
        y: 10,
        alpha: 0,
        duration: 0.375,
      }, '-=0.75');
      tl.current.from(animEls.current.image, {
        duration: 0.5,
        y: 10,
        alpha: 0,
      }, '-=1.5');
      if (asteriskStyle === 1) {
        tl.current.from(animEls.current.asterisk, {
          duration: 1,
          rotation: 180,
          ease: 'power3.out',
          scale: 0,
          alpha: 0,
        }, '-=1.25');
      } else if (asteriskStyle === 3) {
        tl.current.from(animEls.current.asterisk, {
          duration: 0.5,
          rotation: 180,
          ease: 'power3.out',
          scale: 0,
          alpha: 0,
        }, '-=1.25');
      } else {
        tl.current.from(animEls.current.asterisk, {
          duration: 1.25,
          ease: 'power3.out',
          scale: 0,
          alpha: 0,
        }, '-=1.25');
      }
      if (isMobile || isTablet) {
        tl.current.from(animEls.current.content, {
          duration: 0.5,
          y: 10,
          ease: 'power3.out',
          alpha: 0,
        }, '-=0.625');
      }
      tl.current.from(animEls.current.title, {
        duration: 0.5,
        y: 10,
        ease: 'power3.out',
        alpha: 0,
      }, '-=0.625');
      tl.current.from(animEls.current.summary, {
        duration: 0.5,
        y: 10,
        ease: 'power3.out',
        alpha: 0,
      }, '-=0.375');
      tl.current.pause();
    }
  });

  useEffect(() => {
    if (!tl.current) return;
    if (active) {
      tl.current.play();
    }
  }, [active]);
  return (
    <div
      className={`
        flex justify-center
        w-screen max-w-[1550px]
        h-full max-h-full
        transition-all duration-500
        opacity-0 sm:overflow-hidden
        tp:pt-8
        ${activeSlide ? 'opacity-100' : 'opacity-0'}
      `}
      ref={(el) => { animEls.current.panel = el; }}
    >
      <div className="h-full w-full lg:px-16 flex flex-col z-10 ">
        <Grid displayClass="relative h-full flex flex-col lg:grid z-10" wrapperClassName="flex-1 h-full" className="h-full" horizontalPadding="p-0" gapConfig="gap-0">
          <div className="col-span-6 px-8 lg:px-0 flex-0 lg:h-auto lg:mb-0 pt-10 lg:pt-32 z-10 relative">
            <span className={`block ${Typography.eyebrow} uppercase lg:ml-[5px]`} ref={(el) => { animEls.current.eyebrow = el; }}>{eyebrow}</span>
            <div className="relative h-[48px] lg:h-[70px] flex flex-col justify-start" ref={(el) => { animEls.current.name = el; }}>
              <div ref={(el) => { animEls.current.nameList = el; }}>
                {
                  [...Array(nameCount)].map((_, i) => (
                    <h2
                      key={heading + i}
                      className={`${Typography.h2} sm:text-[48px] ${Typography.strokedCharcoal} ${i === 0 && 'fill-me'} ${i !== 0 && 'fade-out pointer-events-none'}`}
                      dangerouslySetInnerHTML={{ __html: heading }}
                    />
                  ))
                }
              </div>
            </div>
          </div>
          <div
            className={`
              flex justify-center
              lg:absolute
              lg:top-32
              lg:flex-0
              col-span-full
              w-full
              flex-0
              lg:max-h-screen
              lg:left-[50%]
              lg:-translate-x-1/2
              lg:bottom-0
              pt-0 lg:pt-0
              px-5 lg:px-0
              flex-1
              ${
                imagePosition === 'Top'
                  ? (isTablet || isMobile ? 'items-center' : 'items-start')
                  : imagePosition === 'Bottom' ? 'items-end' : 'items-center'
              }
            `}
          >
            <div className="relative h-full w-full z-30 flex justify-center items-end">
              <div
                ref={(el) => { animEls.current.image = el; }}
                className={`
                  z-20
                  absolute
                  h-full
                  w-full flex justify-center
                  max-h-full
                  pt-10
                  lg:pt-20
                `}
              >
                <GatsbyImage
                  image={getImage(image.localFile)}
                  alt={heading}
                  objectFit="contain"
                />
              </div>
              {asteriskStyle === 3 && (
                <div
                  className={`
                    w-[80px]
                    mb-[20px]
                    ml-[-80%]
                    tl:ml-[-30%]
                    lg:ml-[-30%]
                    tp:w-[120px]
                    lg:w-[120px]
                  `}
                  ref={(el) => { animEls.current.asterisk = el; }}
                >
                  <IconAsterisk className="w-full h-full" />
                </div>
              )}
            </div>
            {
                (isMobile || isTablet) && asteriskStyle !== 3 && (
                  <div
                    className={`
                      origin-center
                      z-0
                      ${
                        asteriskStyle === 1
                          ? `
                            ${Constants.absoluteCenter}
                            w-[520px]
                            h-[520px]
                            max-w-[95vw] lg:max-w-full
                            max-h-[95vw] lg:max-h-full
                          `
                          : `
                            w-[250vw]
                            h-[250vw]
                            lg:w-[100vw]
                            lg:h-[100vw]
                            absolute
                            top-full
                            left-1/2
                            -translate-y-1/2
                            -translate-x-1/2
                            transform
                            rotate-180
                            origin-center
                            z-0
                          `
                      }
                    `}
                    ref={(el) => { animEls.current.asterisk = el; }}
                  >
                    {
                      asteriskStyle === 1 && (
                        <IconAsterisk className="w-full h-full" />
                      )
                    }
                    {
                      asteriskStyle === 2 && (
                        <IconAsteriskOutline className="w-full h-full case-study-asterisk-skew origin-center" pathClassName="text-tan" />
                      )
                    }
                  </div>
                )
              }
          </div>
          <div
            className={`
              flex flex-col justify-center items-end
              col-span-6 lg:col-span-3 sd:col-start-10
              lg:col-start-10
              pb-16
              p-8 sd:px-4 lg:pr-12 lg:p-0
              border-t-2
              border-t-charcoal
              lg:border-t-0
              bg-white lg:bg-transparent
              z-20
              tp:pb-32
              flex-0
            `}
            ref={(el) => { animEls.current.content = el; }}
          >
            <h4 className={`${Typography.h4} text-[32px] w-full lg:max-w-[280px] mb-5 lg:mb-6`} ref={(el) => { animEls.current.title = el; }}>
              {subheading}
            </h4>
            <div className="font-sans text-[16px] lg:text-[24px] sd:text-[18px] w-full lg:max-w-[280px] leading-[120%]" ref={(el) => { animEls.current.summary = el; }}>
              {description}
            </div>
          </div>
        </Grid>
        {
          asteriskStyle !== 3 && !isMobile && !isTablet && (
            <div
              className={`
                origin-center
                z-0
                ${
                  asteriskStyle === 1
                    ? `
                      ${Constants.absoluteCenter}
                      w-[520px]
                      h-[520px]
                      !top-[55%]
                    `
                    : `
                      w-[100vw]
                      h-[100vw]
                      absolute
                      top-full
                      left-1/2
                      -translate-y-1/2
                      -translate-x-1/2
                      transform
                      rotate-180
                      origin-center
                    `
                }
              `}
              ref={(el) => { animEls.current.asterisk = el; }}
            >
              {
                asteriskStyle === 1 && (
                  <IconAsterisk className="w-full h-full" />
                )
              }
              {
                asteriskStyle === 2 && (
                  <IconAsteriskOutline className="w-full h-full case-study-asterisk-skew origin-center" pathClassName="text-tan" />
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CreatorPanel1;
