export const Colors = {
  black: '#171717',
  charcoal: '#171717',
  gray1: '#333333',
  gray2: '#585858',
  gray3: '#7C878E',
  gray4: '#EAEAEA',
  white: '#ffffff',
  tan: '#D1CCBD',
  red: '#FF4242',
  yellow: '#FFC300',
};
