/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import gsap from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import YouTube from 'react-youtube';
import { Typography } from '../../../../constants/typography';
import Grid from '../../../grid';
import { Constants } from '../../../../constants';

const CreatorPanel4 = ({
  creatorId='',
  data,
  animationComplete,
  activeSlide,
  active,
}) => {
  const {
    eyebrow, youtubeUrl, summary, heading, social, image,
  } = data;
  const tl = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false);
  const [ytPlayer, setYTPlayer] = useState(null);
  const lastTime = useRef(null);
  const currentTimeInterval = useRef(null);
  const animEls = useRef({
    eyebrow: null,
    title: null,
    video: null,
    social: null,
    next: null,
    summary: null,
  });

  const watchTime = () => {
    if (!ytPlayer) return;
    const current = ytPlayer.getCurrentTime();
    lastTime.current = current;
  };

  const videoPlaying = () => {
    currentTimeInterval.current = setInterval(watchTime, 200);
  };

  const videoPaused = () => {
    const current = ytPlayer.getCurrentTime();
    if (current > 0 && Math.abs(current - lastTime.current) < 2) {
      clearInterval(currentTimeInterval.current);
      setPlaying(false);
    }
  };

  useEffect(() => {
    if (!tl.current) {
      tl.current = gsap.timeline({ onComplete: animationComplete });
      tl.current.from(animEls.current.eyebrow, {
        y: 10,
        alpha: 0,
        duration: 0.5,
      }, 0.75);
      tl.current.from(animEls.current.title, {
        y: -10,
        alpha: 0,
        duration: 0.5,
      }, '-=0.5');
      tl.current.from(animEls.current.video, {
        y: -20,
        alpha: 0,
        duration: 0.5,
      }, '-=0.25');
      tl.current.from(animEls.current.social, {
        y: 20,
        alpha: 0,
        duration: 0.5,
      }, '-=0.5');
      tl.current.from(animEls.current.summary, {
        y: -20,
        alpha: 0,
        duration: 0.5,
      }, '-=0.375');
      tl.current.pause();
    }
  });

  useEffect(() => {
    if (shouldPlay) return;
    if (ytPlayer) {
      if (playing) {
        ytPlayer.playVideo();
      } else if (active) {
        ytPlayer.pauseVideo();
      }
    } else if (playing) {
      setShouldPlay(true);
    }
  }, [playing]);

  useEffect(() => {
    if (shouldPlay && ytPlayer) {
      setShouldPlay(false);
      ytPlayer.playVideo();
    }
  }, [shouldPlay, ytPlayer]);

  useEffect(() => {
    if (activeSlide && active) {
      tl.current.play();
    } else {
      tl.current.reverse();
      setPlaying(false);
    }
  }, [activeSlide, active]);
  return (
    <div
      className={`
        flex justify-center
        w-screen
        h-full
        transition-all duration-500
        opacity-0
        sd:px-16
        ${activeSlide ? 'delay-500 opacity-100' : 'delay-0 opacity-0'}
      `}
    >
      <div className="h-full w-full max-w-[1180px] flex flex-col justify-center lg:justify-start lg:pt-32">
        <Grid horizontalPadding="p-0">
          <div className="col-span-full lg:col-span-5 px-8 mb-4 lg:p-0">
            <span className={`${Typography.eyebrow} uppercase lg:ml-[5px] block`} ref={(el) => { animEls.current.eyebrow = el; }}>{eyebrow}</span>
            <h2 className={`${Typography.h3} sm:text-[36px]`} ref={(el) => { animEls.current.title = el; }}>
              {heading}
            </h2>
          </div>
          <div className="col-span-full lg:col-span-6 lg:col-start-7 relative z-0">
            <div className="relative flex justify-center">
              <div
                className="relative lg:rounded-[22px] overflow-hidden mb-6 cursor-pointer tl:w-1/2 lg:w-full"
                onClick={() => setPlaying(true)}
                ref={(el) => { animEls.current.video = el; }}
              >
                <div
                  className={`
                    relative z-20
                    opacity-0
                    transition-all duration-500
                    ${playing ? 'opacity-0 pointer-events-none' : 'opacity-100'}
                  `}
                >
                  <GatsbyImage
                    className="w-full h-full object-cover"
                    image={getImage(image.localFile)}
                    alt={heading}
                  />
                  <span className={`
                    absolute z-30
                    bottom-1 right-1
                    transform scale-50
                    transition-all duration-500
                    pointer-events-none
                    triangle
                    text-white supportshover:group-hover:text-yellow
                    ${Constants.absoluteCenter}
                  `}
                  >
                    ▲
                  </span>
                </div>
                {
                  active && (
                    <YouTube
                      id={"spotterla_creators_"+creatorId.toLowerCase()+"video"}
                      className={`
                        absolute z-10
                        top-0 left-0
                        w-full h-full
                        transition-all duration-500
                        opacity-0
                        ${playing ? 'opacity-100' : 'opacity-0'}
                      `}
                      opts={{
                        playerVars: {
                          playsinline: 1,
                          rel: 0,
                          showinfo: 0,
                          modestbranding: 0,
                          autoplay: false,
                        },
                      }}
                      onReady={(event) => setYTPlayer(event.target)}
                      onPlay={videoPlaying}
                      onPause={videoPaused}
                      onEnd={() => setPlaying(false)}
                      videoId={youtubeUrl.split('?v=')[1]}
                    />
                  )
                }
              </div>
              <div
                className={`
                  ${Typography.h5}
                  leading-[100%]
                  transform rotate-90
                  absolute
                  top-0
                  text-right
                  left-full
                  ml-10
                  origin-top-left
                  hidden
                  lg:block;
                `}
                ref={(el) => { animEls.current.social = el; }}
              >
                {social}
              </div>
            </div>
            <div className={`relative ${Typography.body2} sm:max-w-[90%] px-8 lg:p-0`} ref={(el) => { animEls.current.summary = el; }}>
              {summary}
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default CreatorPanel4;
