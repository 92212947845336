import { Transition } from '@headlessui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useSelector } from 'react-redux';
import { Constants } from '../constants';
import { Typography } from '../constants/typography';
import { selectIsMobile, selectScrollReady } from '../state/reducers/utils';
import Grid from './grid';
import IconAsterisk from './icons/asterisk';

const CreatorList = ({ creators }) => {
  const parentEl = useRef();
  const isMobile = useSelector(selectIsMobile);
  const scrollReady = useSelector(selectScrollReady);
  const [currentIndex, setCurrentIndex] = useState(2);
  const [visible, setVisible] = useState(false);
  const [realIndex, setRealIndex] = useState(0);
  const [allCreators, setAllCreators] = useState(
    [...creators.slice(-2), ...creators.slice(0, creators.length - 2)],
  );
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.on('call', (e, action) => {
        setVisible(action === 'enter');
      });
    }
  }, [scrollReady]);

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.update();
    }
  }, []);

  useEffect(() => {
    let timer;
    if (visible) {
      timer = setTimeout(() => {
        if (realIndex === creators.length - 1) {
          setRealIndex(0);
        } else {
          setRealIndex(realIndex + 1);
        }
        const nextIndex = currentIndex + 1;
        if (nextIndex >= creators.length) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex(nextIndex);
        }
        setTransitioning(true);
        setTimeout(() => {
          if (parentEl.current) {
            setTransitioning(false);
            setAllCreators([...allCreators.slice(1), allCreators[0]]);
          }
        }, 1000);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex, allCreators, visible]);

  return (
    <div
      ref={parentEl}
      className="absolute lg:flex lg:flex-col z-0 text-charcoal px-8 lg:px-0 w-full h-full top-0"
    >
      <Grid
        className="relative w-full h-full z-10 lg:z-0"
        wrapperClassName="h-full"
      >
        <div
          className={`
            relative 
            col-start-1 lg:col-start-4 
            col-end-12 lg:col-end-10 
            w-full h-full
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset={isMobile ? '20%' : '40%'}
          data-scroll-repeat="true"
        >
          {
            creators.map((item, index) => (
              <Transition
                className="absolute w-full h-full flex justify-center items-center"
                key={`creator-transition-${item.name}`}
                show={index === realIndex}
                enter="transition-all ease-expo-out duration-1000 "
                enterFrom="opacity-0 scale-97"
                enterTo="opacity-100 scale-100"
                leave="transition-all duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-97"
              >
                <GatsbyImage
                  image={getImage(item.image.localFile)}
                  alt={item.name}
                  className="max-h-[70vh]"
                  imgStyle={{
                    maxHeight: '70vh',
                    objectFit: 'contain',
                    objectPosition: 'center bottom',
                    alignSelf: 'center',
                  }}
                />
              </Transition>
            ))
          }
        </div>
      </Grid>
      <div
        className={`
          absolute 
          bottom-12 tp:bottom-20 lg:bottom-auto 
          right-8 lg:right-16 
          lg:top-16 
          w-full lg:w-[570px] 
          z-20 lg:z-10 
          mt-3 lg:mt-0
          text-right 
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset={isMobile ? '10%' : '20%'}
        data-scroll-repeat
      >
        <div className={`flex flex-col  transform ${transitioning && 'transition-all duration-1000 -translate-y-9 md:-translate-12 lg:-translate-y-16'}`}>
          {allCreators && allCreators.slice(0, 6).map((creator, index) => (
            <span
              key={`creator-list-${creator.name}`}
              className={`
                creator-title
                ${Typography.h3Alt}
                ${transitioning && 'transition-all duration-1000'}
                ${
                  ((transitioning && index !== 3)
                  || (!transitioning && index !== 2))
                    ? Typography.strokedCharcoal
                    : Typography.strokedCharcoalFill
                }
                ${(index === 0 && transitioning) && 'opacity-0'}
                ${(index === 5 && !transitioning) && 'opacity-0'}
              `}
            >
              {creator.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const ParternsDiversity = ({ content, type }) => {
  const isMobile = useSelector(selectIsMobile);
  const {
    titleFill, titleOutline, creatives, scrollingBanner,
  } = content;
  return (
    <div
      id="diverse-creators"
      className={`
        ${Constants.panelBase}
        bg-white dark-header header-section
        pt-24 lg:pt-16 py-12 tp:py-20 lg:py-32 lg:px-0
        lg:flex lg:flex-col lg:justify-end w-screen h-screen overflow-hidden
      `}
      data-scroll
      data-scroll-call="diversity"
    >
      <div
        className={`
          font-medium
          text-[48px] md:text-[64px] tp:text-[64px] lg:text-[150px] 
          leading-[80%] md:leading-[90%] tp:leading-[90%] lg:leading-[85%]
          px-8 lg:px-16 
          mb-8 lg:mb-4 
          z-10 
          relative
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset={isMobile ? '15%' : '33%'}
        data-scroll-repeat
      >
        {
          type === 'brands'
            ? (
              <>
                <span className="block max-w-[600px]">{titleFill}</span>
                <span className={`block max-w-[950px] ${Typography.strokedCharcoal}`}>{titleOutline}</span>
              </>
            )
            : (
              <>
                <span className={`block max-w-[600px] ${Typography.strokedCharcoal}`}>{titleFill}</span>
                <span className="block max-w-[600px]">{titleOutline}</span>
              </>
            )
        }
      </div>
      <CreatorList creators={creatives} />
      <div
        className={`
          absolute 
          bottom-6 tp:bottom-14 
          lg:bottom-10 
          w-screen 
          ${Constants.fadeIn}
        `}
        data-scroll
        data-scroll-class="opacity-100"
        data-scroll-offset={isMobile ? '5%' : '0'}
        data-scroll-repeat="true"
      >
        <Marquee
          speed={100}
          gradient={false}
        >
          {scrollingBanner.map(({ title }) => (
            <span key={title} className={`${Typography.h4} text-[18px] md:text-[36px] mt-0 lg:mt-10 flex items-center whitespace-nowrap`}>
              <IconAsterisk className="inline-block mx-4 w-6 lg:w-10 mt-1" />
              {title}
            </span>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default ParternsDiversity;
