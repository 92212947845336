import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/app-layout';
import CreatorsHero from '../components/creators/hero';
import ValueProps from '../components/value-props';
import ParternsDiversity from '../components/partners-diversity';
import CreatorsCaseStudies from '../components/creators/case-studies/case-studies';
import CreatorsAreYouNext from '../components/creators/are-you-next';
import { sendTagEvent } from '../constants/google-tags';
import { Constants } from '../constants';
import { utmGet } from '../utils/utmExtractor';

const utm = utmGet();
const connectUrl = process.env.NODE_ENV === Constants.PRODUCTION ? `https://connect.spotter.la/${utm}` : `https://staging-cn.spotter.la/${utm}`;

const CreatorsPage = ({ data }) => {
  const { creators } = data.creators;
  const {
    creatorsA, creatorsB, creatorsC, creatorsD, creatorsE, trueStories,
  } = creators;
  return (
    <Layout defaultHeaderColor="text-white">
      <SEO title={data.creators.title} />
      <div className="w-full h-full" data-scroll-section>
        <CreatorsHero
          content={{
            outlineText: creatorsA.title,
            fillText: creatorsB.title,
            bodyText: creatorsB.paragraph,
          }}
        />
        <ValueProps
          id="no-catch"
          content={{
            title: creatorsC.title,
            subtitle: false,
            items: creatorsC.items,
            buttonA: creatorsC.buttonA,
          }}
          carouselConfig={{
            inactiveColor: 'after:bg-gray1 after:opacity-50',
          }}
          primaryButtonProps={{
            id: 'spotterla_creators_strikeadeal',
            href: connectUrl,
            target: '_blank',
            onClick: () => {
              sendTagEvent('click', {
                event_category: 'Creators',
                event_label: 'Strike a deal',
              });
            },
          }}
        />
        <CreatorsCaseStudies content={trueStories} />
        <CreatorsAreYouNext content={creatorsE} />
        <ParternsDiversity content={creatorsD} type="creators" />
      </div>
    </Layout>
  );
};

export default CreatorsPage;

export const creatorsData = graphql`
  {
    creators: wpPage(databaseId: {eq: 360}) {
      title
      creators {
        creatorsA {
          title
        }
        creatorsB {
          title
          paragraph
        }
        creatorsC {
          title
          items {
            title
            subtitle
            icon {
              localFile {
                publicURL
              }
            }
          }
          buttonA {
            label
            link
          }
        }
        creatorsD {
          titleFill
          titleOutline
          creatives {
            name
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          scrollingBanner {
            title
          }
        }
        creatorsE {
          title
          ctaDescription
          ctaLabel
          facts {
            titleOutline
            titleFill
            description
          }
        }
        trueStories {
          titleFill
          titleOutline
          caseStudies {
            title
            backgroundColor
            slides {
              ... on WpPage_Creators_TrueStories_caseStudies_Slides_IntroTemplate {
                type: fieldGroupName
                asteriskStyle
                description
                eyebrow
                imagePosition
                subheading
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              ... on WpPage_Creators_TrueStories_caseStudies_Slides_AudioTemplate {
                type: fieldGroupName
                eyebrow
                heading
                social
                audio {
                  audioFile {
                    localFile {
                      publicURL
                    }
                  }
                  captionsFile {
                    localFile {
                      publicURL
                    }
                  }
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              ... on WpPage_Creators_TrueStories_caseStudies_Slides_StatisticsTemplate {
                type: fieldGroupName
                asteriskFillText
                asteriskOutlineText
                description
                eyebrow
              }
              ... on WpPage_Creators_TrueStories_caseStudies_Slides_VideoTemplate {
                type: fieldGroupName
                eyebrow
                youtubeUrl
                summary
                heading
                social
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
// export const creatorsData = graphql`
//   {
//     creators: wpPage(databaseId: {eq: 138}) {
//       title
//       creators {
//         creatorsA {
//           title
//         }
//         creatorsB {
//           title
//           paragraph
//         }
//         creatorsC {
//           title
//           items {
//             title
//             subtitle
//             icon {
//               localFile {
//                 publicURL
//               }
//             }
//           }
//           buttonA {
//             label
//             link
//           }
//         }
//         creatorsD {
//           titleFill
//           titleOutline
//           creatives {
//             name
//             image {
//               localFile {
//                 childImageSharp {
//                   gatsbyImageData
//                 }
//               }
//             }
//           }
//           scrollingBanner {
//             title
//           }
//         }
//         creatorsE {
//           title
//           ctaDescription
//           ctaLabel
//           facts {
//             titleOutline
//             titleFill
//             description
//           }
//         }
//         trueStories {
//           titleFill
//           titleOutline
//           caseStudies {
//             title
//             backgroundColor
//             slides {
//               ... on WpPage_Creators_TrueStories_caseStudies_Slides_IntroTemplate {
//                 type: fieldGroupName
//                 asteriskStyle
//                 description
//                 eyebrow
//                 imagePosition
//                 subheading
//                 image {
//                   localFile {
//                     childImageSharp {
//                       gatsbyImageData
//                     }
//                   }
//                 }
//               }
//               ... on WpPage_Creators_TrueStories_caseStudies_Slides_AudioTemplate {
//                 type: fieldGroupName
//                 eyebrow
//                 heading
//                 social
//                 audio {
//                   audioFile {
//                     localFile {
//                       publicURL
//                     }
//                   }
//                   captionsFile {
//                     localFile {
//                       publicURL
//                     }
//                   }
//                 }
//                 image {
//                   localFile {
//                     childImageSharp {
//                       gatsbyImageData
//                     }
//                   }
//                 }
//               }
//               ... on WpPage_Creators_TrueStories_caseStudies_Slides_StatisticsTemplate {
//                 type: fieldGroupName
//                 asteriskFillText
//                 asteriskOutlineText
//                 description
//                 eyebrow
//               }
//               ... on WpPage_Creators_TrueStories_caseStudies_Slides_VideoTemplate {
//                 type: fieldGroupName
//                 eyebrow
//                 youtubeUrl
//                 summary
//                 heading
//                 social
//                 image {
//                   localFile {
//                     childImageSharp {
//                       gatsbyImageData
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
