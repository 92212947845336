/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import gsap, { ScrollToPlugin } from 'gsap/all';
import slugify from 'slugify';
import { useSelector } from 'react-redux';
import { Typography } from '../../../constants/typography';
import CreatorCaseStudy from './case-study';
import { selectIsMobile } from '../../../state/reducers/utils';
import { Constants } from '../../../constants';

gsap.registerPlugin(ScrollToPlugin);

const CreatorsCaseStudies = ({ content }) => {
  const { titleFill, titleOutline, caseStudies } = content;
  const isMobile = useSelector(selectIsMobile);
  const sectionRefs = useRef([]);
  const studiesRef = useRef(null);
  const studyRef = useRef(null);

  const scrollToCurrent = (caseStudyIndex) => {
    let offset = sectionRefs.current[caseStudyIndex];
    if (window.innerHeight > 816) {
      offset = (
        sectionRefs.current[caseStudyIndex].offsetParent.offsetParent.offsetTop
        + sectionRefs.current[caseStudyIndex].offsetParent.offsetTop
        + sectionRefs.current[caseStudyIndex].offsetTop
      )
      + (sectionRefs.current[caseStudyIndex].offsetHeight / 2)
      - (window.innerHeight / 2);
    }
    window.locoScroll.scrollTo(offset, { duration: 1000, ease: 'easeOut' });
  };
  const animationComplete = () => {
    // called when panel 1 animation complete
  };
  const goToNext = (currentIndex) => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < caseStudies.length) {
      scrollToCurrent(nextIndex);
    }
  };
  return (
    <div className="bg-tan transition-all duration-300 w-screen relative z-20 max-w-screen overflow-hidden" id="true-stories" ref={studiesRef}>
      <div className="w-full flex justify-center items-center pt-36">
        <h2
          className={`
            ${Typography.h1}
            text-center
            mb-8
            lg:mb-16
            leading-[95%]
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset={isMobile ? '20%' : '20%'}
          data-scroll-repeat="true"
        >
          <span className="block">{titleFill}</span>
          <span className={`${Typography.strokedCharcoal}`}>{titleOutline}</span>
        </h2>
      </div>
      <div className="w-full relative" id="case-studies" ref={studyRef}>
        <div className="w-full">
          {caseStudies.map((caseStudy, index) => {
            const id = slugify(caseStudy.title);
            return (
              <div
                id={`${id}-section-${index}`}
                key={`${id}-case-studies`}
                data-index={index}
                className="w-full"
                ref={(el) => { sectionRefs.current[index] = el; }}
              >
                <CreatorCaseStudy
                  id={id}
                  isLast={index === caseStudies.length - 1}
                  scrollToCurrent={scrollToCurrent}
                  caseStudy={caseStudy}
                  goToNext={goToNext}
                  studiesRef={studiesRef}
                  caseStudyIndex={index}
                  animationComplete={animationComplete}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CreatorsCaseStudies;
